<template>
  <a-config-provider :locale="locale">
    <div id="app">
      <headBar v-if="$route.meta.hasBar" :goRelation="goRelation" />
      <router-view v-if="!$route.meta.keepAlive" />
      <keep-alive>
        <router-view v-if="$route.meta.keepAlive" />
      </keep-alive>
      <footBar v-if="$route.meta.hasBar || $route.meta.hasFooter" ref="footBar" />
    </div>
  </a-config-provider>
</template>

<script>
import zhCN from "ant-design-vue/lib/locale-provider/zh_CN";
import headBar from "@/components/headBar";
import footBar from "@/components/footBar";
export default {
  components: {
    headBar,
    footBar,
  },
  data() {
    return {
      locale: zhCN,
    };
  },
  mounted() {

  },
  methods: {
    goRelation() {
      scroll({
        top: this.$refs.footBar.$el.offsetTop,
        behavior: "smooth",
      });
    },
  },
};
</script>

<style>
#app {
  position: relative;
}
.ant-table-selection-column {
  width: 16px !important;
  padding-right: 0 !important;
  padding-left: 16px !important;
}
.ant-table-thead > tr > th,
.ant-table-tbody > tr > td {
  padding: 16px 5px !important;
  height: 65px;
  box-sizing: border-box;
}
.ant-modal-confirm-title {
  font-size: 14px;
  font-weight: 300 !important;
  color: #333333 !important;
}
.ant-modal-header {
  border-bottom: 0!important;
  border-radius: 18px 18px 0 0!important;
  padding-top: 36px!important;
  padding-bottom: 0!important;
}
.ant-modal-close-icon {
  display: inline-block!important;
}
.ant-modal-content {
  border-radius: 18px !important;
  background: #ffffff;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.3) !important;
}
.ant-modal {
  width: 420px !important;
}
.ant-modal .anticon {
  display: none !important;
}
.ant-modal .ant-btn {
  border: 1px solid rgb(13, 87, 213) !important;
  color: #0052d9 !important;
}
.ant-modal-footer {
  border-top: 0 !important;
  padding: 20px 16px !important;
}
.ant-modal-body {
  padding: 48px 48px 30px 48px !important;
}
.ant-modal .ant-btn-primary {
  border: 0 !important;
  color: #fff !important;
  background-color: #0052d9 !important;
}
.minCont {
  min-width: 985px;
}
.ant-space-item {
  margin-right: 0!important;
}
.ant-space-item a {
  color: #0052d9 !important;
}
.ant-checkbox-inner {
  position: relative;
  padding: 1px 3px !important;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 2px;
  background-color: #fff;
}
.curs {
  cursor: pointer;
}
.ant-checkbox-wrapper {
  position: relative;
  padding: 1px 3px 0px !important;
  border: 1px solid rgb(220, 220, 220);
  border-radius: 2px;
  background-color: #fff;
}
.ant-message {
  z-index: 10010 !important;
}
.ant-checkbox-indeterminate::before {
  content: "";
  width: 18px;
  height: 18px;
  background-color: #0052d9;
  position: absolute;
  top: -2px;
  left: -1px;
  z-index: 70;
  border-radius: 2px;
}
.ant-space-item .a-delete {
  color: #ea0000 !important;
}
.ant-table-thead > tr:first-child > th:last-child {
  text-align: right;
}
.ant-space {
  display: flex !important;
  align-items: center;
  justify-content: end;
}
.ant-checkbox-wrapper::before {
  content: "";
  width: 18px;
  height: 18px;
  background-color: #fff;
  position: absolute;
  top: 2px;
  left: 2px;
  z-index: 70;
  border-radius: 2px;
}
.ant-table-thead > tr > th {
  background-color: white !important;
  white-space: nowrap;
}
.ant-form-item {
  display: flex!important;
}
.ant-form-item-label {
  text-align: left!important;
}
.ant-checkbox-wrapper-checked::before {
  content: "";
  width: 18px;
  height: 18px;
  background-color: #0052d9;
  position: absolute;
  top: 2px;
  z-index: 99;
  border-radius: 2px;
}
.ant-modal .modal-btn-red {
  border: 0 !important;
  background-color: #ea0000 !important;
  color: white !important;
}
.ant-modal-wrap {
  display: flex;
  justify-content: center;
  top: 0 !important;
}
.a-flex {
  display: flex;
  align-items: center;
}
.ant-switch-checked {
  background-color: #0052d9!important;
}
div::first-letter, p::first-letter, span::first-letter, a::first-letter {
  text-transform: uppercase;
}
</style>
