<template>
  <div class="foot">
    <div class="foot-detail">
      <div class="foot-word">
        <div class="foot-word-detail">
          <div class="title">联系我们</div>
          <div class="site">
<!--            公司地址：浙江省杭州市西湖区天目山路217号江南电子大厦503室-->
          </div>
          <div class="tel">
            <div style="margin-right: 80px">联系方式：(+86) 0571-86691087</div>
            <div>邮箱：market01@veryengine.cn</div>
          </div>
        </div>
                <div class="foot-img-detail">
                  <a
                    href="http://cool360.veryengine.cn"
                    target="_blank"
                    rel="external nofollow"
                  >
                    <img src="../assets/cool360.svg" alt="" />
                  </a>
<!--                  <img src="../assets/wxxy.png" alt="" style="margin-top: 40px" />-->
                </div>
      </div>
<!--      <div class="foot-app-list">-->
<!--        <a-->
<!--          href="http://www.cool360.com/#/home"-->
<!--          target="_blank"-->
<!--          rel="external nofollow"-->
<!--        >-->
<!--          <img src="../assets/foot__cool.svg" alt="" />-->
<!--        </a>-->
<!--        <a-->
<!--          href="https://wwxy.walkclass.com/"-->
<!--          target="_blank"-->
<!--          rel="external nofollow"-->
<!--        >-->
<!--          <img src="../assets/foot__ww.svg" alt="" />-->
<!--        </a>-->
<!--        <a class="check__img">-->
<!--          <img src="../assets/wxgzh.svg" alt="" />-->
<!--          <img src="../assets/wxgzh_QR.png" alt="" class="float__QR" />-->
<!--        </a>-->
<!--        <a-->
<!--          href="https://v.qq.com/s/videoplus/422698251"-->
<!--          target="_blank"-->
<!--          rel="external nofollow"-->
<!--        >-->
<!--          <img src="../assets/foot__tx.svg" alt="" />-->
<!--        </a>-->
<!--        <a-->
<!--          href="http://i.youku.com/u/UNDIzODg3NzM3Ng==?spm=a2h0c.8166622.PhoneSokuPgc_1.dportrait"-->
<!--          target="_blank"-->
<!--          rel="external nofollow"-->
<!--        >-->
<!--          <img src="../assets/foot__yk.svg" alt="" />-->
<!--        </a>-->
<!--        <a-->
<!--          href="https://space.bilibili.com/480792332?from=search&seid=12057666893283738037"-->
<!--          target="_blank"-->
<!--          rel="external nofollow"-->
<!--        >-->
<!--          <img src="../assets/bilibili.svg" alt="" />-->
<!--        </a>-->
<!--      </div>-->
      <div class="foot-relation-detail">
        粤网文【2017】6138-1456号&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;粤B2-20090059&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;粤公网安备
        44030502008569号&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Copyright ©
        1998 - 2022 Tencent. All Rights Reserved.
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "footBar",
};
</script>

<style scoped>
.foot {
  opacity: 1;
  background: #1e2331;
  min-width: 900px;
}
.foot-detail {
  margin-left: 13%;
  width: 74%;
  padding: 20px 0;
  color: #ffffff;
}
.foot-word {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
  font-weight: 300;
}
.foot-word .title {
  font-size: 28px;
  font-weight: 500;
}
.foot-img-detail {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.foot-img-detail img {
  width: 233px;
}
.foot-app-list {
  margin-top: 20px;
  display: flex;
  align-items: center;
}
.foot-app-list img {
  margin-right: 68px;
}
.site {
  margin: 20px 0;
}
.foot-relation-detail {
  margin-top: 20px;
}
.tel {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.check__img {
  position: relative;
}
.check__img:hover .float__QR {
  width: 160px;
  height: 160px;
}
.float__QR {
  width: 0px;
  height: 0px;
  transition: all 0.6s;
  position: absolute;
  top: -170px;
  left: -62px;
}
</style>
