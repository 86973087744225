<template>
  <section>
    <div class="head">
      <div class="head__detail">
        <div class="head__logo" @click="goHome">
          <div class="head__logo--left">
            <img src="../assets/cool360.svg" />
          </div>
        </div>
        <div class="head__nav">
          <div :class="['href', { englishItem: isEnglish }]">
            <div class="head__nav__item items" @click="plugIn">
              <div @click="openCollege">
                <span class="text" v-if="!isEnglish">万维学院</span>
                <span class="englishText" style="display: inline-block">
                  <a-tooltip>
                    <template slot="title">
                      Veryengine Training
                    </template>
                    <span class="text" v-if="isEnglish">Veryengine Training</span>
                  </a-tooltip>
                </span>
              </div>
            </div>
            <div class="head__nav__item--other items">
              <span class="text" v-if="!isEnglish">万维引擎  </span>
              <div class="englishText">
                <span class="text" v-if="isEnglish">VeryEngine</span>
              </div>
              <div :class="['head__nav__items__card',{ englishCard: isEnglish }]">
                <div class="card__item" @click="openVeryengine5('https://www.veryengine.cn/#/')">
                  万维引擎平台V5.0
                </div>
                <div class="card__item" @click="openVeryengine5('https://admin.veryengine.cn/')">
                  万维引擎4.0
                </div>
                <div class="card__item" @click="openVeryengine5('https://veryexpo.cool360.com/')">
                  数字展览引擎平台V1.0
                </div>
                <div class="card__item"  @click="openVeryengine5('https://verymedia.cool360.com/')">
                  交互式媒体编辑器
                </div>
                <div class="card__item" style="margin-bottom: 0"  @click="openVeryengine5('https://veryar.cool360.com/')">
                  AR编辑器
                </div>
              </div>
            </div>
            <div class="head__nav__item--other items">
              <span v-if="!isEnglish" class="text" @click="onEnter('https://jdykt.walkclass.com/dataCenter/?topOrgId=15171#/courseSquareHome')">机电云课堂</span>
              <div class="englishText">
                <a-tooltip>
                  <template slot="title">
                    ME&MEP classroom
                  </template>
                  <span v-if="isEnglish" class="text" @click="onEnter('https://jdykt.walkclass.com/dataCenter/?topOrgId=15171#/courseSquareHome')"> ME&MEP classroom </span>
                </a-tooltip>
              </div>
            </div>
            <div class="head__nav__item--other items">
              <span v-if="!isEnglish" class="text" @click="onEnter('https://szykt.walkclass.com/dataCenter/?topOrgId=15171#/courseSquareHome')">思政云课堂</span>
              <div class="englishText">
                <a-tooltip placement="topLeft">
                  <template slot="title">
                    Ideological and political classroom
                  </template>
                  <span v-if="isEnglish" class="text" @click="onEnter('https://szykt.walkclass.com/dataCenter/?topOrgId=15171#/courseSquareHome')"> Ideological and political classroom</span>
                </a-tooltip>
              </div>
            </div>
          </div>
          <div class="head__nav__items" v-if="!userId" style="margin-left: 42px">
            <span @click="goLogin" class="text">注册</span>&nbsp;&nbsp;&nbsp;/&nbsp;&nbsp;&nbsp;<span @click="goLogin" class="text">登录</span>
          </div>
          <div class="head__nav__items--login" v-else>
            <div class="my-detail" @click="goMyDetail">
              <img
                :src="showImg"
                alt=""
                width="40"
                height="40"
                style="border-radius: 50%"
                v-if="showImg"
              />
              <img
                src="../assets/test-man.svg"
                alt=""
                width="40"
                height="40"
                style="border-radius: 50%"
                v-else
              />
              &nbsp;<span class="nickName ignore">{{nickName}}</span>
            </div>
            <div class="head__nav__items__card change__card ignore">
              <div v-if="!isEnglish" class="card__item" @click="languageChange('english')">
                切换到英文
              </div>
              <div v-if="isEnglish" class="card__item" @click="languageChange('chinese_simplified')">
                Switch to Chinese
              </div>
              <div v-if="!isEnglish" class="card__item" style="margin-bottom: 0" @click="loginOut">
                退出登录
              </div>
              <div v-if="isEnglish" class="card__item" style="margin-bottom: 0" @click="loginOut">
                Log Out
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div style="height: 72px"></div>
  </section>
</template>

<script>
// import translate from "@/utils/translate";
import { imageBaseUrl } from "@/services/HttpService";
let xhr = new XMLHttpRequest();
export default {
  name: "home-index",
  props: ["goRelation"],
  data() {
    return {
      isAuditor: false,
      ticket: localStorage.getItem("ticket"),
      userId: localStorage.getItem("userId"),
      showImg: localStorage.getItem("profilePictureUrl"),
      nickName: localStorage.getItem("nickName"),
      isEnglish: false, // 当前语言是否是英文
    };
  },
  watch: {
    deep: true, // 深度监听
    immediate: true, // 第一次初始化渲染就可以监听到
  },
  created() {},
  mounted() {
    this.isEnglish = translate.language.getCurrent() === 'english';
    if (this.showImg) {
      if (this.showImg.includes("null")) {
        this.showImg = "";
      } else {
        if (!this.showImg.includes("https")) {
          this.showImg = imageBaseUrl + this.showImg;
        }
      }
    }
    const roles = JSON.stringify(localStorage.getItem("roleIds"));
    if (roles.indexOf("AUDITOR") > -1) {
      this.isAuditor = true;
    } else {
      this.isAuditor = false;
    }
    this.$store.state.routeNavTitle = this.$store.state.routeNavTitle
      ? this.$store.state.routeNavTitle
      : "素材";
  },
  destroyed() {
    this.$store.state.isMyPage = true;
  },
  methods: {
    // 退出登录
    loginOut() {
      var that = this;
      this.$confirm({
        title: "确定要退出登录吗?",
        okText: "确定",
        okType: "primary",
        cancelText: "取消",
        onOk() {
          xhr.open("post","https://utp.veryengine.cn/User/SignOut");
          xhr.withCredentials = true;
          xhr.send();
          xhr.onreadystatechange = function (){
            if (xhr.readyState === 4&& xhr.status === 200){
              let resp = JSON.parse(xhr.response);
              console.log(resp)
              if (resp.code === "00000"){
                that.$message.success("已退出", 1.5)
              }
            }
          }
          localStorage.removeItem("userId");
          localStorage.removeItem("nickName");
          localStorage.removeItem("token");
          that.$store.dispatch("openVuex", "");
          that.$store.dispatch("checkId", "817485bf52754d8880cb97789f88141d");
          that.$store.dispatch("checkSideMenu", "edit");
          that.$store.state.isMyPage = true;
          that.$router.push({
            name: "Login",
          });
        },
      });
    },
    // 切换语言
    languageChange(type){
      translate.changeLanguage(type); // 切换语言
      this.isEnglish = type === 'english';
      this.$bus.$emit("languageHasChanged", type)
    },
    goHome() {
      this.$router.push({
        name: "HomeIndex",
      });
    },
    material() {
      scroll({
        top: 0,
        behavior: "smooth",
      });
      if (this.$route.name !== "HomeIndex") {
        this.$router.push({
          name: "HomeIndex",
        });
      }
      this.$store.state.routeNavTitle = "素材";
      this.$store.dispatch("openVuex", "素材");
      this.$store.dispatch("checkId", "817485bf52754d8880cb97789f88141d");
    },
    plugIn() {
      scroll({
        top: 0,
        behavior: "smooth",
      });
      if (this.$route.name !== "HomeIndex") {
        this.$router.push({
          name: "HomeIndex",
        });
      }
      this.$store.state.routeNavTitle = "插件";
      this.$store.dispatch("openVuex", "插件");
      this.$store.dispatch("checkId", "80b933f37f5a643e2fbadae729fbd67b");
    },
    openCollege() {
      xhr.open("get","https://utp.veryengine.cn/Open/xuebei");
      xhr.withCredentials = true;
      xhr.send();
      xhr.onreadystatechange = function (){
        if (xhr.readyState === 4&& xhr.status === 200){
          let resp = JSON.parse(xhr.response);
          if (resp.code === "00000"){
            window.open(`https://wwxy.walkclass.com/sso/walkclass/courseSquare/3025?ticket=${resp.data.ticket}`)
          }else {
            window.open("https://wwxy.walkclass.com/dataCenter/?topOrgId=3025#/courseSquareHome")
          }
        }
      }
    },
    goLogin() {
      this.$store.state.isMyPage = true;
      this.$router.push({
        name: "Login",
      });
    },
    goMyDetail() {
      this.$router.push({ name: "MyIssue" });
      this.$store.dispatch("checkSideMenu", "release");
    },
    // 进入万维引擎
    openVeryengine5(url) {
      const that = this;
      xhr.open("post","https://utp.veryengine.cn/User/HasLogin");
      xhr.withCredentials = true;
      xhr.send();
      xhr.onreadystatechange = function () {
        if (xhr.readyState === 4&& xhr.status === 200){
          let resp = JSON.parse(xhr.response);
          if(resp.code === "00000") {
            window.open(url);
          } else {
            that.$message.warning("用户未登录，现在跳转至登录页");
            localStorage.removeItem("userId");
            localStorage.removeItem("nickName");
            localStorage.removeItem("token");
            that.$store.dispatch("openVuex", "");
            that.$store.dispatch("checkId", "817485bf52754d8880cb97789f88141d");
            that.$store.dispatch("checkSideMenu", "edit");
            that.$store.state.isMyPage = true;
            setTimeout(()=> {
              that.$router.push({
                name: "Login"
              })
            },1000)
          }
        }
      }
    },
    contactUs() {
      this.$store.state.isCheckUs = true;
      this.goRelation();
      this.$store.state.routeNavTitle = "联系我们";
      this.$store.dispatch("openVuex", "联系我们");
    },
    onEnter(url){
      window.open(url,"_blank")
    }
  },
};
</script>

<style scoped lang="less">
.head {
  width: 100%;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  z-index: 1000;
  background-color: white;
  box-shadow: 0px 2px 20px 0px rgba(0, 0, 0, 0.13);
}
.head__detail {
  width: 74%;
  padding: 9px 4px;
  display: flex;
}
.head__logo {
  display: flex;
}
.head__logo--left img {
  width: 169px;
  height: 54px;
}
.head__logo--right {
  height: 54px;
  display: flex;
  flex-direction: column;
  justify-content: end;
}
.head__nav {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.head__nav__item {
  color: #333333;
  height: 54px;
  line-height: 54px;
  position: relative;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}
.head__nav__items span {
  cursor: pointer;
}
.foot__line {
  position: absolute;
  bottom: 0;
  width: 70px;
  height: 4px;
  opacity: 1;
  background: #0052d9;
  right: 0;
  left: calc(50% - 35px);
  display: none;
}
.head__nav__items {
  color: #333333;
  height: 54px;
  line-height: 54px;
  position: relative;
  text-align: right;
  white-space: nowrap;
}
.href {
  flex: 1;
  display: flex;
  flex-direction: row-reverse;
  font-weight: normal;
}
.items {
  width: 148px;
}
.englishItem .englishText{
  max-width: 148px;
  padding: 0 20px;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.head__nav__items--login {
  white-space: nowrap;
  color: #333333;
  height: 54px;
  line-height: 54px;
  position: relative;
  min-width: 138px;
}
.my-detail {
  display: flex;
  align-items: center;
  justify-content: center;
}
.head__nav__items--login span {
  margin: 0 10px;
  cursor: pointer;
}
.head__nav__items {
  position: relative;
}
.head__nav__item--other {
  color: #333333;
  height: 54px;
  line-height: 54px;
  position: relative;
  cursor: pointer;
  text-align: center;
  white-space: nowrap;
}
.head__nav__item--other:hover .head__nav__items__card {
  display: block;
  width: 168px;
}
.head__nav__items--login:hover .head__nav__items__card {
  display: block;
}

.icon2 {
  display: none;
}
.head__nav__item--other:hover .icon1 {
  display: none;
}
.head__nav__item--other:hover .icon2 {
  display: inline-block;
}
.card__item {
  padding-left: 8px;
  height: 26px;
  line-height: 26px;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 0em;
  color: #333333;
  margin-bottom: 10px;
  text-indent: 0.2em;
}
.card__item:hover {
  background-color: #E3EDFF;
}
.head__nav__items__card {
  display: none;
  border-radius: 8px;
  opacity: 1;
  padding: 10px 4px;
  text-align: left;
  background: #FFFFFF;

  box-sizing: border-box;
  border: 1px solid #DCDCDC;

  box-shadow: 0px 4px 2px 0px rgba(0, 0, 0, 0.3);
  cursor: pointer;
}
.englishCard {
  width: 175px;
}
.change__card {
  width: 138px;
}
.nickName {
  color: #0052D9;
  font-size: 16px;
}
.text {
  color: #333333;
  font-weight: 600;
  font-size: 16px;
}
</style>
